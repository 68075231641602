/* global $ */

const toggleShare = function($el) {
    const url = $el.data('url');

    let style = 'toolbar,scrollbars,resizable,top=100,left=100,width=800';
    style += ',height=500';

    window.open(url, '_blank', style);
}

export default {
    toggleShare
}
