// Import jquery like this for slick-carousel to work
import $ from 'jquery';
import 'slick-carousel';

const Slider = (el) => {
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) $('body').addClass('browser-IE');

    const $el = $(el);
    const $slider = $el.find('.js-slider-list');
    const $prev = $el.find('.js-slider-prev');
    const $next = $el.find('.js-slider-next');
    $slider.slick({
        centerMode: true,
        centerPadding: 0,
        nextArrow: $next,
        prevArrow: $prev,
    });
}

const reloadSlider = (el) => {
    const $el = $(el);
    $el.slick('setPosition');
}

const goToSlide = (el, i) => {
    const $el = $(el);
    $el.slick('slickGoTo', i);
}

export { Slider, reloadSlider, goToSlide };
