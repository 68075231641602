const status = response => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(response)
    }
}

const json = response => {
    return response.json()
}

export default {
    post: (url, data = {}, options = {}) => {
        const defaultArgs = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        if (typeof JSSettings !== "undefined") {
          defaultArgs.headers["X-WP-Nonce"] = JSSettings.nonce
        }

        return fetch(url, {...defaultArgs, ...options})
        .then(status)
        .then(json)
    },

    get: (url, data = null, options = {}) => {
        const defaultArgs = {};

        if (data) {
            const query = Object.keys(data)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                .join('&');

            url = url + (url.match(/\?/) ? '&' : '?') + query
        }

        return fetch(url, {...defaultArgs, ...options})
        .then(status)
        .then(json)
    }
}