import {isValidSwedishOrganizationNumber, isValidSSN} from "./donation/validators";

export function wcValidationSwedishSSN() {
    const wrapper = $(this).closest('.form-row');
    if (isValidSSN($(this).val())) {
        wrapper.addClass('woocommerce-validated');
    } else {
        wrapper.addClass('woocommerce-invalid');
    }
}

export function wcValidationSwedishOrganizationNumber() {
    const wrapper = $(this).closest('.form-row');
    if (isValidSwedishOrganizationNumber($(this).val())) {
        wrapper.addClass('woocommerce-validated');
    } else {
        wrapper.addClass('woocommerce-invalid');
    }
}
