import { helpers } from 'vuelidate/lib/validators';
import luhn from 'luhn';

// Same validation is used in backend, src/validators.php
export const isValidSSN = value => {
    if (!value) return true;

    // Remove all non-digits characters
    value = value.replace(/\D/g, '');
    if (value.length !== 12) {
        return false;
    }
    if (value.length === 12) {
        value = value.slice(2);
    }

    const month = value.substring(2, 4);
    const day = value.substring(4, 6);
    if (month > 12 || day > 31) {
        return false;
    }

    return luhn.validate(value);
};

// Same validation is used in backend, src/validators.php
export const isValidSwedishOrganizationNumber = value => {
    if (!value) {
        return false;
    }

    if (!value.match(/^\d{6}-?\d{4}$/)) {
        return false;
    }

    value = value.replace(/\D/, '').slice(-10);
    return luhn.validate(value);
};

export const optional = () => {
    return true;
};

export const manualAddress = (value, fields) => {
    if (fields.obfuscatedAddress || value) {
        return true;
    }

    return false;
};

export const isClearingNo = value => {
    // Remove all dashes and check if it is a number
    value = value.replace(/\-/g, '');
    if(isNaN(value)) {
        return false;
    }

    const minLength = 4;
    let maxLength = 4;
    // Banks where clearing no starting with 8 have an extra digit
    if(value.substring(0, 1) === '8') {
        maxLength = 5;
    }
    return value.length >= minLength && value.length <= maxLength;
};

export const isAccountNo = value => {
    // Remove all dashes and spaces and check if it is a number
    value = value.replace(/\s|\-/g, '');
    if(isNaN(value)) {
        return false;
    }

    // Different lengths depending on bank
    const minLength = 7;
    const maxLength = 10;
    return value.length >= minLength && value.length <= maxLength;
};

export const isDate = value => {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value);
};

export const isMinToday = value => {
    const today = new Date();
    const date = new Date(value);
    if (today.getFullYear() !== date.getFullYear()) {
        return today.getFullYear() < date.getFullYear();
    }
    if (today.getMonth() !== date.getMonth()) {
        return today.getMonth() < date.getMonth();
    }
    return today.getDate() <= date.getDate();
};

export const validateIf = (validation, condition) => {
    if (!condition) return true;

    return value => validation(value);
};

export const maxRows = rows => value => {
    return value.split('\n').length <= rows;
};
