/* global $ */
/* eslint no-unused-vars: 0 */
import 'whatwg-fetch';

import Donation from './donation/donation';
import Agroforestry from './agroforestry';
import Cookie from './cookie';
import Share from './share';
import Copy from './copy';
import Video from './video';
import Checkout from './checkout';
import {
    CertificateForm,
    DigitalCertificate,
    UnloadDigitalCertificate
} from './certificate';
import { MenuToggle } from './menu';
import { SiteListToggle } from './site-list-toggle';
import {ScrollLock} from './scroll-lock';
import tracking from './tracking';
import { Slider } from './slider';
import Gallery from './gallery';
import Submenu from './submenu';
import Minicart from './minicart';
import { init as initErrorHandler } from './error-handler';
import {
    wcValidationSwedishOrganizationNumber,
    wcValidationSwedishSSN
} from './wc-validation';
import { VideoPlayer, VideoModal } from './video-player';
import FadeImage from './fade-image';
import TMRegisterLead from './tm-register-lead';
import EmbedMessage from './embed-message';

initErrorHandler();

window.tracking = tracking;
$(document).ready(() => {

    const $agroforestry = $('.js-agroforestry');
    if ($agroforestry.length) {
        $agroforestry.map((i, v) => Agroforestry(v));
    }

    const $cookie = $('.js-cookie');
    if ($cookie.length) {
        $cookie.map((i, v) => Cookie(v));
    }

    const $video = $('.js-video');
    if ($video.length) {
        $video.map((i, v) => Video(v));
    }

    const $checkout = $('.js-checkout-loader');
    if ($checkout.length) {
        Checkout();
    }

    const $certificateForm = $('.js-certificate-form');
    if ($certificateForm.length) {
        $certificateForm.map((i, v) => CertificateForm(v));
    }

    const $digitialCertificate = $('.js-digital-certificate');
    if ($digitialCertificate.length) {
        $digitialCertificate.map((i, v) => DigitalCertificate(v));
    }

    const $menuToggle = $('.js-menu-toggle, .js-menu-cover');
    if ($menuToggle.length) {
        $menuToggle.map((i, v) => MenuToggle(v));
    }

    const $siteListToggle = $('.js-site-toggle');
    if ($siteListToggle.length) {
        $siteListToggle.map((i, v) => SiteListToggle(v));
    }

    const $scrollLock = $('.js-menu-toggle, .js-menu-cover');
    if ($scrollLock.length) {
        $scrollLock.map((i, v) => ScrollLock(v));
    }

    const $slider = $('.js-slider');
    if ($slider.length) {
        $slider.map((i, v) => Slider(v));
    }

    const $gallery = $('.js-gallery');
    if ($gallery.length) {
        $gallery.map((i, v) => Gallery(v));
    }

    const $submenu = $('.js-submenu');
    if ($submenu.length) {
        $submenu.map((i, v) => Submenu(v));
    }

    const $minicart = $('.js-minicart');
    if ($minicart.length) {
        $minicart.map((i, v) => Minicart(v));
    }

    $('.validate-swedish-organization-number input').blur(
        wcValidationSwedishOrganizationNumber
    );
    $('.validate-swedish-ssn input').blur(wcValidationSwedishSSN);

    const $videoPlayer = $('.js-video-player');
    if ($videoPlayer.length) {
        $videoPlayer.map((i, v) => VideoPlayer(v));
    }

    const $videoModal = $('.js-video-modal');
    if ($videoModal.length) {
        $videoModal.map((i, v) => VideoModal(v, false));
    }

    const $fadeImage = $('.page-template-template-reportage');
    if ($fadeImage.length) {
        $fadeImage.map((i, v) => FadeImage(v, false));
    }

    const $embedMessage = $('.js-embed-message');
    if ($embedMessage.length) {
        $embedMessage.map((i, v) => EmbedMessage(v));
    }

    /**
     * .js-share and .js-copy is binded on click like this
     * because they are injected on the page via ajax through
     * gravityforms on submission, hence we need to bind on
     * document. We probably should refactor this to always
     * show a hidden share-button on initial rendering and
     * display it on submit instead. Would be cleaner and
     * this approach is probably bad for frontend performance
     */

    $(document).on("click", ".js-share", (e) => {
        e.preventDefault();

        const $el = $(e.currentTarget);
        Share.toggleShare($el);
    });

    $(document).on("click", ".js-copy", (e) => {
        const $el = $(e.currentTarget);
        Copy.copyData($el);
    });
});

$(window).on('beforeunload', () => {
    const $digitialCertificate = $('.js-digital-certificate');
    if ($digitialCertificate.length) {
        $digitialCertificate.map((i, v) => UnloadDigitalCertificate(v));
    }
});

const $donation = $('#vue');
if ($donation.length) {
    Donation('#vue');
}

const $tmRegisterLead = $('#tm-register-lead');
if ($tmRegisterLead.length) {
    console.log('hi');
    TMRegisterLead('#tm-register-lead');
}
