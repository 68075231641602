
const showImage = (el) => {
  var windowHeight = $(window).height();
  $(el).each(function(){
      var thisPos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (topOfWindow + windowHeight - 200 > thisPos ) {
          $(this).addClass("fadeInImage");
      }
  });
}

const fadeImage = () => {
    $(document).ready(function(){
        showImage('img');
    });
    $(window).scroll(function() {
        showImage('img');
    });
};

export default fadeImage;
