import copy from 'clipboard-copy';

const copyData = ($el) => {
    const text = $el.data('copy');

    $el.addClass('js-state-copied');
    copy(text);

    setTimeout(() => {
        $el.removeClass('js-state-copied');
    }, 10000);

}

export default {
    copyData
}