const Submenu = (el) => {
    const $el = $(el);
    $el.change(() => {
        const val = $el.val();
        const url = window.location.href;
        if(val !== '' && val !== url) {
            window.location = val;
        }
    });   
}

export default Submenu;