const SiteListToggle = (el) => {
    const $el = $(el)
    $el.click(() => {
        $('.site-list').toggleClass('site-list__toggleOpen');
        $('.js-toggle').toggle('slow');
    })

}

export { SiteListToggle }
