
const ScrollLock = (el) => {
    const $el = $(el)
    $el.click(() => {
        $('body').toggleClass('js-scroll-lock');
    })

}

export { ScrollLock }
