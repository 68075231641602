import { reloadSlider, goToSlide } from './slider';

const Gallery = (el) => {
    const $el = $(el);
    const $modal = $el.find('.js-modal');
    $el.find('.js-modal-open').click((e) => {
        e.preventDefault();

        const i = $(e.currentTarget).data('index');
        const $slider = $modal.find('.js-slider-list');

        $modal.fadeIn(() => {
            $modal.addClass('js-state-open');

            reloadSlider($slider);
            goToSlide($slider, i);
        });
    });

    $modal.find('.js-modal-close').click(() => {
        $modal.removeClass('js-state-open');
        $modal.fadeOut('slow');
    });
}

export default Gallery;
