/* global $ */

let videoBgPlayer = null;
let playerBgType = null;
let videoModalPlayer = null;
let playerModalType = null;

let youtubeApiPromise = null;
let vimeoApiPromise = null;

const parseUrl = url => {
    let a = document.createElement('a');
    a.href = url;
    return a;
};

const injectJsApi = scriptUrl => {
    let tag = document.createElement('script');
    tag.src = scriptUrl;

    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return new Promise(resolve => {
        tag.addEventListener('load', () => {
            resolve();
        });
    });
};

const getDomElements = $el => {
    const $player = $('.js-video-player');
    const $modal = $('.js-video-modal');
    const $wrapper = $el.parent();
    const $controlButton = $('.js-video-control');
    const $modalButtonOpen = $('.js-video-modal-open');
    const $modalButtonClose = $modal.find('.js-video-modal-close');

    return {
        player: $player,
        modal: $modal,
        wrapper: $wrapper,
        controlButton: $controlButton,
        modalButtonOpen: $modalButtonOpen,
        modalButtonClose: $modalButtonClose,
        playText: $controlButton.data('play'),
        pauseText: $controlButton.data('pause'),
    };
};

window.youtubeReadyFunction = [];

window.onYouTubeIframeAPIReady = function() {
    window.youtubeReadyFunction.map(x => x());
}

const YouTubePlayer = async ($el, url, id, asBg = true) => {
    const urlParts = url.match(
        /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/
    );
    const videoId = urlParts[5];
    await youtubeApiPromise;

    let $videoContainer = $(`#${id}`);
    $videoContainer.empty();

    let innerContainer = document.createElement('div');
    const videoContainerInnerId = (innerContainer.id = `${id}-inner`);
    const elements = getDomElements($el);

    $videoContainer.append(innerContainer);

    if(asBg) {
        window.youtubeReadyFunction.push(function() {
            playerBgType = 'youtube';
            window.player = videoBgPlayer = new window.YT.Player(videoContainerInnerId, {
                videoId: videoId,
                playerVars: {
                    controls: 0,
                    loop: 1,
                    playlist: videoId,
                    modestbranding: 1,
                    showInfo: 0,
                    mute: 1,
                    rel: 0
                },
                events: {
                    onReady: () => {
                        videoBgPlayer.playVideo();
                        setTimeout(() => {
                            elements.wrapper.removeClass('paused');
                        }, 800);
                    },
                    onStateChange: (state) => {
                        if(state === 'playing') {
                            showPauseButton(elements);
                            elements.wrapper.removeClass('paused');
                        } else if(state === 'paused') {
                            showPlayButton(elements);
                        }
                    },
                }
            });
        });

        elements.controlButton.click(() => {
            if (videoBgPlayer.getPlayerState() === 1) {
                videoBgPlayer.pauseVideo();
            } else {
                videoBgPlayer.playVideo();
            }
        });

        if ($(window).width() < 768){
            videoBgPlayer.pauseVideo();
            showPlayButton(elements);
        }

    } else {
        window.youtubeReadyFunction.push(function() {
            playerModalType = 'youtube';
            window.modalPlayer = videoModalPlayer = new window.YT.Player(videoContainerInnerId, {
                videoId: videoId,
                playerVars: {},
            });
        });
    }
};

const VimeoPlayer = async ($el, url, id, asBg = true) => {
    await vimeoApiPromise;

    let $videoContainer = $(`#${id}`);
    $videoContainer.empty();
    $videoContainer.addClass('FeaturedContent__VimeoVideoContainer');

    const elements = getDomElements($el);

    if(asBg) {
        let options = {
            url: url,
            loop: 1,
            autoplay: 1,
            background: 1,
            title: 0,
            byline: 0,
            controls: 0,
            muted: 1,
            quality: '4K',
        };

        playerBgType = 'vimeo';
        videoBgPlayer = new Vimeo.Player(id, options);

        videoBgPlayer.on('play', function() {
            elements.wrapper.removeClass('paused');
            showPauseButton(elements);
        });

        videoBgPlayer.on('pause', function() {
            showPlayButton(elements);
        });

        elements.controlButton.click(() => {
            videoBgPlayer.getPaused().then((paused) => {
                if(paused) {
                    videoBgPlayer.play();
                } else {
                    videoBgPlayer.pause();
                }
            });
        });

        if ($(window).width() < 768){
            videoBgPlayer.pause();
            showPlayButton(elements);
        }

    } else {
        let options = {
            url: url
        };

        playerModalType = 'vimeo';
        videoModalPlayer = new Vimeo.Player(id, options);
    }
};

const showPauseButton = (elements) => {
    elements.controlButton.find('span').text(elements.pauseText)
    elements.controlButton.addClass('js-state-pause').removeClass('js-state-play');
};

const showPlayButton = (elements) => {
    elements.controlButton.find('span').text(elements.playText)
    elements.controlButton.addClass('js-state-play').removeClass('js-state-pause');
};

const initVideo = ($el, url, id, asBg = true) => {
    let player;
    if (url.indexOf('youtube.com') > -1) {
        player = YouTubePlayer;
        if (!youtubeApiPromise) {
            youtubeApiPromise = injectJsApi('//www.youtube.com/player_api');
        }
    } else if (url.indexOf('vimeo.com') > -1) {
        player = VimeoPlayer;
        if (!vimeoApiPromise) {
            vimeoApiPromise = injectJsApi('//player.vimeo.com/api/player.js');
        }
    } else {
        console.warn(
            `Error: video player functionality not implemented for provider: ${
                parseUrl(url).host
            }`
        );
        return;
    }

    player($el, url, id, asBg);
};

const resizeVideo = () => {
  var iframeImage = $('.video-bg__player');

  $(window).on('load resize', function(){
    var w = $(window).width(),
        h = $(window).height();

    const isPortrait = w/h < 16/9;
    iframeImage.toggleClass('js-state-portrait', isPortrait);
    iframeImage.toggleClass('js-state-landscape', !isPortrait);

  });
}

const VideoPlayer = el => {
    const $el = $(el);
    const url = $el.data('video-url');
    initVideo($el, url, 'video-bg');
    resizeVideo();
};

const VideoModal = el => {
    const $el = $(el);
    const elements = getDomElements($el);

    const url = $el.find('iframe').attr('src');
    initVideo($el, url, 'video-modal', false);

    elements.modalButtonOpen.click(() => {
        elements.modal.addClass('js-state-open');
        $('body,html').addClass('js-state-no-scroll');
        if(videoBgPlayer) {
            if(playerBgType === 'vimeo') {
                videoBgPlayer.pause();
            } else if(playerBgType === 'youtube') {
                videoBgPlayer.pauseVideo();
            }
        }
    });

    elements.modalButtonClose.click(() => {
        elements.modal.removeClass('js-state-open');
        $('body,html').removeClass('js-state-no-scroll');
        if(videoBgPlayer) {
            if(playerBgType === 'vimeo') {
                videoBgPlayer.pause();
            } else if(playerBgType === 'youtube') {
                videoBgPlayer.pauseVideo();
            }
        }
        if(videoModalPlayer) {
            if(playerModalType === 'vimeo') {
                videoModalPlayer.pause();
            } else if(playerModalType === 'youtube') {
                videoModalPlayer.pauseVideo();
            }
        }
    });
};

export { VideoPlayer, VideoModal };
