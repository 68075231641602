import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";

export function init() {
    Sentry.init({
        ...window.SENTRY_SDK_CONFIG,

        integrations: [new Integrations.BrowserTracing()],
    });
}
export default class Logger {
    error(message, extra = null) {
        if (extra) {
            Sentry.setContext('error context', {extra})
        }
        Sentry.captureMessage(message, Sentry.Severity.Error);
    }
}

