import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueLocalStorage from 'vue-localstorage';
import {
    email,
    numeric,
    decimal,
    required,
    requiredIf,
    maxLength
} from 'vuelidate/lib/validators';
import Iti from 'intl-tel-input';
import Velocity from 'velocity-animate';
import {
    optional,
    isValidSSN,
    isValidSwedishOrganizationNumber,
    isClearingNo,
    isAccountNo,
    isDate,
    isMinToday,
    validateIf,
    maxRows
} from './validators';


import billecta, {eventListener as billectaEventListener} from '../billecta';
import http from '../http';
import getSparPerson from '../spar';
import { trackEvent, trackCart } from '../tracking';
import Logger from '../error-handler';

import {
    GIFT_TYPE_TRACKING_NAMES,
    SECTION_TRACKING_NAMES,
    XHR_STATUS_IDLE,
    XHR_STATUS_LOADING,
    XHR_STATUS_SUCCESS,
    XHR_STATUS_ERROR,
    XHR_STATUS_EMPTY,
} from './constants';

const logger = new Logger();

let V = Velocity;

Vue.use(Vuelidate);
Vue.use(VueLocalStorage, { name: 'store' });

const Donation = el => {
    // Settings from wp
    const {
        type,
        postId,
        orderId,
        customerId,
        campaignCode,
        legalAgreement,
        sectionsData,
        currency,
        locale,
        certificatePrintCost,
        certificateTextMaxLength,
        certificateTextMaxRows,
        certificateFromMaxLength,
        certificateShowFromName,
        amount,
        amounts,
        minAmount,
        allowDecimalAmounts,
        externalAmount,
        defaultPayment,
        quantity,
        minQuantity,
        certificateImages,
        isClimateCompensation,
        trackingName,
        cartInfo,
        isLoggedIn,
        leadGuid,
    } = settings;
    const sections = Object.keys(sectionsData);

    const sectionValidate = sections
        .map(s => `groups.${s}`);

    const forms = {
        section: sectionValidate
    };

    const defaultGroups = Object.assign(
        {},
        ...sections.map(i => ({ [i]: false }))
    );

    let defaultAmounts = amounts.map(a => parseFloat(a));

    let useCustomAmount = false;
    let customAmount = '';

    const defaultAmountIndex = Math.floor(amounts.length / 2);
    let defaultAmount = defaultAmounts[defaultAmountIndex]
        ? defaultAmounts[defaultAmountIndex]
        : amount;

    if (externalAmount) {
        defaultAmount = externalAmount;
        useCustomAmount = !defaultAmounts.includes(externalAmount);
        customAmount = useCustomAmount ? defaultAmount : '';
    }

    const defaultQuantity = parseInt(quantity || 1);
    const defaultTotalCost = defaultAmount * defaultQuantity;

    const certificateImagesAreSelectable =
        certificateImages && certificateImages.length > 0;
    const defaultCertificateImage = certificateImagesAreSelectable
        ? certificateImages[0]
        : {};

    // Set order states depending on if they include the specific section
    const isCompanyOrder = sections.includes('company');
    const isCertificateOrder = sections.includes('certificate');
    const isTreeOrder = sections.includes('treeCount');
    const isMonthlyOrder = sections.includes('automaticPayment');

    const intlTelUtils =
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js';
    const intlTelLoaded = window.intlTelInputGlobals.loadUtils(intlTelUtils);

    var app = new Vue({
        el: '#vue',
        directives: {
            intltelinput: {
                inserted: (el, _binding, vnode) => {
                    const vueApp = vnode.context;
                    const input = el.querySelector('input[type="tel"]');
                    const countryKey = `${input.name}Country`;
                    const sanitizedKey = `${input.name}Sanitized`;

                    let defaultCountry = 'SE';
                    if (
                        vueApp.fields &&
                        vueApp.fields[countryKey] &&
                        vueApp.fields[countryKey].iso2
                    ) {
                        defaultCountry = vueApp.fields[countryKey].iso2;
                    }

                    const options = {
                        separateDialCode: true,
                        initialCountry: defaultCountry
                    };
                    if (input.name === 'swishPhone') {
                        options.onlyCountries = ['SE'];
                        options.allowDropdown = false;
                    } else {
                        options.preferredCountries = ['SE'];
                    }

                    const iti = new Iti(input, options);

                    intlTelLoaded.then(() => {
                        vueApp.fields[sanitizedKey] = iti.getNumber();
                        vueApp.fields[
                            countryKey
                        ] = iti.getSelectedCountryData();

                        input.addEventListener('countrychange', () => {
                            vueApp.fields[
                                countryKey
                            ] = iti.getSelectedCountryData();
                        });
                    });
                },
                update: (el, _binding, vnode) => {
                  const input = el.querySelector('input[type="tel"]');

                  const countryKey = `${input.name}Country`;
                  const sanitizedKey = `${input.name}Sanitized`;
                  const isValidKey = `${input.name}IsValid`;

                  const iti = window.intlTelInputGlobals.getInstance(input);

                  const number = iti.getNumber()
                  vnode.context.fields[isValidKey] = !number || window.intlTelInputUtils.isValidNumber(number);
                  vnode.context.fields[sanitizedKey] = number;
                  vnode.context.fields[countryKey] = iti.getSelectedCountryData();
                }
            }
        },
        data: {
            type: type,
            postId: postId,
            orderId: orderId,
            campaignCode: campaignCode,
            currency: currency,
            locale: locale,
            activeSection: sections[0],
            sections: sections,
            amounts: defaultAmounts,
            minAmount: parseInt(minAmount),
            certificatePrintCost: parseInt(certificatePrintCost),
            certificateTextMaxLength: parseInt(certificateTextMaxLength),
            certificateTextMaxRows: parseInt(certificateTextMaxRows),
            quantity: quantity || 0,
            currentSubmit: '',
            trackingName: trackingName,

            certificateImages: certificateImages,
            certificateImagesAreSelectable: certificateImagesAreSelectable,
            selectedCertificateImage: defaultCertificateImage,
            selectedCertificateImageStyle: {},

            obfuscatedAddress: {
                firstName: '',
                lastName: '',
                address: '',
                postalCode: '',
                city: ''
            },

            fields: {
                amount: defaultAmount,
                customAmount,

                quantity: defaultQuantity,
                treeCount: defaultQuantity,

                ssn: '',
                sparId: '',

                firstName: '',
                lastName: '',
                address: '',
                address2: '',
                postalCode: '',
                city: '',
                companyName: '',
                companyRegistrationNumber: '',
                email: '',
                phone: '',
                phoneSanitized: '',
                phoneIsValid: false,
                phoneCountry: {},

                showShippingFields: false,
                shippingFirstName: '',
                shippingLastName: '',
                shippingAddress: '',
                shippingAddress2: '',
                shippingPostalCode: '',
                shippingCity: '',

                payment: defaultPayment,

                swishPhone: '',
                swishPhoneSanitized: '',
                swishPhoneIsValid: '',
                swishPhoneCountry: {},

                certificate: false,
                certificateOther: false,
                certificateName: '',
                certificateFromName: '',
                certificateMessage: '',
                certificateImageId: null,

                recipientEmail: '',
                recipientSelect: '',
                recipientDate: '',
                recipientSelectedDate: 'memorial',
                memorialOrderData: [],
                recipientName: '',
                recipientAddress: '',
                recipientAddress2: '',
                recipientPostalCode: '',
                recipientCity: '',

                // For automatic payments
                bank: '',
                bankAccountNumberSelected: '',
                clearingNo: '',
                accountNo: ''
            },
            billectaIsPolling: false,
            billectaBankAccountPublicId: null,
            billectaQRCode: null,
            billectaBankIdAutoStartToken: null,
            billectaCurrentDevice: false,
            bankIdUrl: 'bankid://',

            digitalSignature: null,

            showPreviewModal: false,
            previewType: 'digital',

            useCustomAmount,

            showAddressFields: (isLoggedIn !== true) ? false : true,
            showAccountFields: false,
            showObfuscatedAddress: false,
            showSparLoader: false,
            showSparError: false,
            showMissingAddressError: false,

            showCertificateName: !isTreeOrder,

            isSwishPhone: false,
            isOtherCertificate: false,
            isPrintCertificate: false,

            allowSwishPhoneOverride: true,

            xhrStatusOrderStatus: XHR_STATUS_IDLE,
            xhrStatusBankAccount: XHR_STATUS_IDLE,
            xhrStatusSubmit: XHR_STATUS_IDLE,
            xhrStatusSubmitDonorToBe: XHR_STATUS_IDLE,
            xhrStatusMessageType: type,

            submitErrorMessage: '',
            bankAccountNumbers: [],

            showBankIDLoadingSpinner: false,
            openBankIDOnCurrentDevice: false,

            totalCost: defaultTotalCost,

            error: false,
            touchedGroups: Object.assign({}, defaultGroups),
            groupError: Object.assign({}, defaultGroups),
            hideFieldError: '',

            groups: {
                amount: ['fields.amount'],
                cart: ['fields.amount'],
                personal: [
                    'fields.ssn',
                    'fields.sparId',
                    'fields.email',
                    'fields.phone',
                    'fields.firstName',
                    'fields.lastName',
                    'fields.address',
                    'fields.postalCode',
                    'fields.city',
                    'fields.shippingFirstName',
                    'fields.shippingLastName',
                    'fields.shippingAddress',
                    'fields.shippingAddress2',
                    'fields.shippingPostalCode',
                    'fields.shippingCity'
                ],
                company: [
                    'fields.email',
                    'fields.phone',
                    'fields.companyName',
                    'fields.companyRegistrationNumber',
                    'fields.firstName',
                    'fields.lastName',
                    'fields.address',
                    'fields.postalCode',
                    'fields.city',
                    'fields.shippingFirstName',
                    'fields.shippingLastName',
                    'fields.shippingAddress',
                    'fields.shippingAddress2',
                    'fields.shippingPostalCode',
                    'fields.shippingCity'
                ],
                payment: ['fields.payment', 'fields.swishPhone'],
                automaticPayment: [
                    'fields.bankAccountNumberSelected',
                    'fields.bank',
                    'fields.clearingNo',
                    'fields.accountNo'
                ],
                certificate: [
                    'fields.certificate',
                    'fields.certificateOther',
                    'fields.certificateName',
                    'fields.certificateMessage',
                    'fields.certificateFromName',
                    'fields.recipientEmail',
                    'fields.recipientSelect',
                    'fields.recipientDate',
                    'fields.recipientSelectedDate',
                    'fields.memorialOrderData',
                    'fields.recipientName',
                    'fields.recipientAddress',
                    'fields.recipientAddress2',
                    'fields.recipientPostalCode',
                    'fields.recipientCity',
                    'fields.certificateImageId'
                ],
                treeCount: ['fields.treeCount']
            }
        },

        validations() {
            // Multiple validations with requiredIf doesn't work, custom validateIf used instead

            const reqAddress = requiredIf(
                () => this.showAddressFields || this.activeSection === 'company'
            );
            const reqShippingAddress = requiredIf(
                () => this.fields.showShippingFields
            )
            return {
                fields: {
                    amount: {
                        decimal,
                        minValue: v => v >= this.minAmount,
                        required
                    },
                    customAmount: {
                        requiredIf: requiredIf(() => this.useCustomAmount),
                        validateCustomAmount: validateIf(
                            numeric,
                            this.useCustomAmount
                        )
                    },

                    quantity: {
                        numeric,
                        minValue: v => v >= minQuantity,
                        required
                    },
                    treeCount: {
                        numeric,
                        minValue: v => v >= minQuantity,
                        required
                    },

                    ssn: {
                        requiredIf: requiredIf(() => !isLoggedIn),
                        validateSSN: validateIf(isValidSSN, isLoggedIn || !isLoggedIn)
                    },
                    sparId: {
                        requiredIf: requiredIf(() => !this.showAddressFields),
                    },

                    firstName: {
                        requiredIf: reqAddress
                    },
                    lastName: {
                        requiredIf: reqAddress
                    },

                    companyName: { required },
                    companyRegistrationNumber: {
                        requiredIf: requiredIf(() => !isLoggedIn),
                        validateCompanyRegistrationNumber:  validateIf(
                          isValidSwedishOrganizationNumber,
                          !isLoggedIn
                        ),
                    },

                    address: { requiredIf: reqAddress },
                    address2: { optional },
                    postalCode: { requiredIf: reqAddress },
                    city: { requiredIf: reqAddress },
                    email: {
                      email,
                      required,
                    },
                    phone: {
                      phone: () => this.fields.phoneIsValid,
                      requiredIf: requiredIf(() => isLoggedIn !== true),
                    },

                    showShippingFields: { optional },
                    shippingFirstName: { requiredIf: reqShippingAddress },
                    shippingLastName: { requiredIf: reqShippingAddress },
                    shippingAddress: { requiredIf: reqShippingAddress },
                    shippingAddress2: { optional },
                    shippingPostalCode: { requiredIf: reqShippingAddress },
                    shippingCity: { requiredIf: reqShippingAddress },

                    payment: { required },
                    swishPhone: {
                        requiredIf: requiredIf(() => this.isSwishPhone),
                        validateSwishPhone: () => this.fields.swishPhoneIsValid || !this.isSwishPhone
                    },

                    bank: {
                        requiredIf: requiredIf(() => !this.showAccountFields)
                    },
                    bankAccountNumberSelected: {
                        requiredIf: requiredIf(() => !this.showAccountFields)
                    },
                    clearingNo: {
                        requiredIf: requiredIf(() => this.showAccountFields),
                        validateClearingNo: validateIf(
                            isClearingNo,
                            this.showAccountFields,
                        )
                    },
                    accountNo: {
                        requiredIf: requiredIf(() => this.showAccountFields),
                        validateClearingNo: validateIf(
                            isAccountNo,
                            this.showAccountFields,
                        )
                    },

                    certificate: { optional },
                    certificateOther: { optional },
                    certificateName: {
                        requiredIf: requiredIf(() => this.showCertificateName)
                    },
                    certificateImageId: {
                        requiredIf: requiredIf(
                            () => this.certificateImagesAreSelectable
                        )
                    },
                    certificateFromName: {
                        requiredIf: requiredIf(() => certificateShowFromName),
                        validateRequiredFromName: validateIf(
                            maxLength(certificateFromMaxLength),
                            certificateShowFromName,
                        )
                    },
                    certificateMessage: {
                        requiredIf: requiredIf(() => this.showCertificateName),
                        maxLength: maxLength(this.certificateTextMaxLength),
                        maxRows: maxRows(this.certificateTextMaxRows)
                    },

                    recipientEmail: {
                        requiredIf: requiredIf(() => this.isOtherCertificate),
                        validateRequiredEmail: validateIf(
                            email,
                            this.isOtherCertificate
                        )
                    },
                    recipientSelect: { optional },
                    recipientDate: {
                        requiredIf: requiredIf(() => this.isPrintCertificate),
                        validateRequiredDate: validateIf(
                            isDate,
                            this.isPrintCertificate
                        ),
                        validateRequiredMinToday: validateIf(
                            isMinToday,
                            this.isPrintCertificate
                        )
                    },
                    recipientSelectedDate: { optional },
                    recipientName: {
                        requiredIf: requiredIf(() => this.isPrintCertificate)
                    },
                    recipientAddress: {
                        requiredIf: requiredIf(() => this.isPrintCertificate)
                    },
                    recipientAddress2: { optional },
                    recipientPostalCode: {
                        requiredIf: requiredIf(() => this.isPrintCertificate)
                    },
                    recipientCity: {
                        requiredIf: requiredIf(() => this.isPrintCertificate)
                    },
                    memorialOrderData: { optional },

                },
                groups: this.groups,
                forms: forms
            };
        },

        watch: {
            fields: {
                handler() {
                    this.setStore();
                    this.groupError = Object.assign({}, defaultGroups);
                },
                deep: true
            },
            'fields.payment'(val) {
                this.isSwishPhone = val === 'swish';
            },
            'fields.amount'() {
                this.updateTotalCost();
            },
            'fields.customAmount'() {
                this.updateTotalCost();
            },
            'fields.certificateOther'(val) {
                this.isOtherCertificate = val;
            },
            'fields.certificate'(val) {
                this.isPrintCertificate = val;

                this.updateTotalCost();
            },
            'billectaBankIdAutoStartToken'(val) {
              this.bankIdUrl = val
                ? 'bankid:///?autostarttoken=' + val + '&redirect=null'
                : '';

              if (this.bankIdUrl && app.billectaCurrentDevice) {
                if (!this.openBankIDOnCurrentDevice) {
                  this.openBankIDOnCurrentDevice = true;
                  window.location.href = this.bankIdUrl;
                }
              }
            },
            'fields.quantity'() {
                this.updateTotalCost();
            },
            'fields.certificateImageId'() {
                const image = this.findCertificateImage(
                    this.fields.certificateImageId
                );
                if (image) {
                    this.selectedCertificateImage = image;
                    this.selectedCertificateImageStyle = {
                        backgroundImage: `url('${image.src}')`
                    };
                }
            },
            'fields.recipientSelectedDate'() {
                this.memorialOrderDateSelected(this.fields);
            },
        },

        filters: {
            convertLineBreaks: val => {
                const str = $('<div/>')
                    .html(val)
                    .text();
                return str.replace(new RegExp('\r?\n', 'g'), '<br />');
            },
            formatAmount: val => {
                let amount = parseFloat(val);
                if (isNaN(amount)) {
                    amount = 0;
                }

                const options = {
                    currency: currency,
                    style: 'currency'
                };

                if (!allowDecimalAmounts) {
                    options.minimumFractionDigits = 0;
                    options.maximumFractionDigits = 0;
                }
                return amount.toLocaleString(locale, options);
            }
        },

        created: function() {
            const store = JSON.parse(this.$store.get(this.type));
            if (isClimateCompensation) {
                store.fields.amount = this.amounts[0];
            }

            // If store exists and not expired, remove and reset order data to storage
            if (store && store.expire > Date.now()) {
                if (type === 'checkout') {
                    // Since we read amount from sessions the local storage value may not be
                    // correct on pageload for cart checkouts
                    store.fields.amount = this.fields.amount;
                }

                this.xhrStatusOrderStatus = XHR_STATUS_LOADING;

                if (store.validateOnCreate) {
                    this.$v.fields.$touch();
                }

                this.$store.remove(this.type);

                // Set order id if received from backend
                if (this.orderId !== '') {
                    store.orderId = this.orderId;
                }

                // If user goes back from payment the same order should be updated
                // But only if order hasn't a successful payment
                if (store.orderId !== '') {
                    this.getOrderStatus(store.orderId)
                        .then(status => {
                            this.xhrStatusOrderStatus = XHR_STATUS_IDLE;
                            if (status === 'pending') {
                                this.setData(store);
                            } else if(status === 'cancelled') {
                                // Remove order id, but keep order info
                                store.orderId = '';
                                this.setData(store);
                            }
                        })
                        .catch(e => {
                            this.xhrStatusOrderStatus = XHR_STATUS_ERROR;
                            logger.error('Donation.getOrderStatus failed', e);
                        });
                } else {
                    this.xhrStatusOrderStatus = XHR_STATUS_IDLE;
                    this.setData(store);
                }
            } else { // Store doesn't exist or is expired
                this.xhrStatusOrderStatus = XHR_STATUS_IDLE;
            }

            if (isTreeOrder) {
                this.onTreeCountUpdate();
            }

            if (this.$store.get('billectaBankAccountPublicId')) {
                this.getBankAccounts();
            }
        },

        methods: {
            onChangeFocusOut (e) {
                if(e.target.name === 'certificateName'){
                    this.getMemorialOrderData(this.fields.certificateName, this.fields);
                }
            },
            onChangeAmount: function(_e) {
                this.useCustomAmount = false;
                this.hideFieldError = '';
            },
            onClickCustomAmount: function() {
                if (!this.useCustomAmount) {
                    this.$refs.inputCustomAmount.focus();
                    this.fields.amount = this.fields.customAmount;
                    this.useCustomAmount = true;
                    this.hideFieldError = '';
                }
            },
            onInputCustomAmount: function() {
                this.fields.amount = this.fields.customAmount;
                this.hideFieldError = 'customAmount';
            },
            onChangeCustomAmount: function() {
                this.fields.amount = this.fields.customAmount;
                this.hideFieldError = '';
            },
            onInputField: function(e) {
                if (this.$v.fields[e.target.name].$error) {
                    this.hideFieldError = e.target.name;
                }
                if (e.target.name === 'treeCount') {
                    this.onTreeCountUpdate();
                }
            },
            onChangeField: function(e) {
                this.hideFieldError = '';
                this.$v.fields[e.target.name].$touch();
                if (e.target.name === 'swishPhone') {
                    this.onSwishPhoneChange();
                }
                if (e.target.name === 'phone') {
                    this.copyPhoneToSwish();
                }
            },
            formatSSN: function() {
                let value = this.fields.ssn.replace(/\D/g, '');
                if (value.length === 10) {
                    let prefix = '19';
                    const date = new Date();
                    const currentYear = date
                        .getFullYear()
                        .toString()
                        .substring(2);
                    const ssnYear = this.fields.ssn.substring(0, 2);
                    if (parseInt(ssnYear) <= parseInt(currentYear)) {
                        prefix = '20';
                    }
                    return `${prefix}${value}`;
                }

                return value;
            },

            toggleAddress: function() {
                this.showAddressFields = !this.showAddressFields;
                if (this.showAddressFields && this.$v.fields.ssn.$invalid) {
                    this.fields.ssn = '';
                }
                if (this.showAddressFields) {
                    this.showObfuscatedAddress = false;
                    this.showMissingAddressError = false;
                }
                this.showSparError = false;
                this.setStore();
            },
            toggleAccount: function() {
                this.showAccountFields = !this.showAccountFields;
                this.setStore();
            },
            togglePreviewModal: function() {
                this.showPreviewModal = !this.showPreviewModal;
                $('body,html').toggleClass(
                    'js-state-no-scroll',
                    this.showPreviewModal
                );
            },
            togglePreviewType: function(type) {
                this.previewType = type;
            },
            onEnterSection: function(el, done) {
                V(el.parentElement, 'scroll', {
                    duration: 500,
                    offset: -100,
                    delay: 200
                });
                V(el, 'slideDown', { duration: 500, complete: done });
            },
            onAfterEnterSection: function(el) {
                V(el.parentElement, 'scroll', { duration: 500, offset: -100 });
            },
            onLeaveSection: function(el, done) {
                V(el, 'slideUp', { duration: 200, complete: done });
            },
            onEnterExpandable: function(el, done) {
                V(el, 'slideDown', {
                    duration: 300,
                    easing: 'easeOutQuart',
                    complete: done
                });
            },
            onLeaveExpandable: function(el, done) {
                V(el, 'slideUp', {
                    duration: 200,
                    easing: 'easeOutQuart',
                    complete: done
                });
            },
            onClickChangeSection: function(section) {
                this.activeSection = section;
                this.setStore();
            },
            onClickNextSection: function(section = false) {
                this.$v.groups[section].$touch();

                if (!section) {
                    this.activeSection = '';
                    return;
                }

                this.touchedGroups[section] = true;

                if (this.$v.groups[section].$invalid) {
                    this.groupError[section] = true;

                    if (
                        section === 'personal' &&
                        !this.showAddressFields &&
                        this.$v.fields.sparId.$invalid
                    ) {
                        this.showMissingAddressError = true;
                    }
                    return;
                }

                this.groupError[section] = false;

                this.trackOnClickNextSection(section);

                const currentIndex = this.sections.indexOf(this.activeSection);
                const nextItems = this.sections.slice(currentIndex + 1);
                const nextUntouchedItem = nextItems.find(x => {
                    return !this.touchedGroups[x] || this.$v.groups[x].$invalid;
                });
                this.activeSection = nextUntouchedItem;
                this.setStore();
            },

            onClickAddress() {
                if (!this.fields.ssn.length || this.$v.fields.ssn.$invalid) {
                    logger.error(
                        'User tried to get address without a SSN, should not be possible'
                    );
                    return;
                }

                this.showAddressFields = false;
                this.showObfuscatedAddress = false;
                this.showSparLoader = true;
                this.showSparError = false;
                this.showMissingAddressError = false;

                getSparPerson(this.formatSSN())
                    .then(response => {
                        this.showObfuscatedAddress = true;
                        this.showSparLoader = false;
                        this.showSparError = false;
                        this.fields.sparId = response.sparId;
                        this.obfuscatedAddress = response.obfuscate;
                    })
                    .catch(e => {
                        logger.error('getSparPerson failed', e);
                        this.showObfuscatedAddress = false;
                        this.showSparLoader = false;
                        this.showSparError = true;
                        this.fields.sparId = '';
                    });
            },
            onSwishPhoneChange() {
                if (this.allowSwishPhoneOverride) {
                    this.allowSwishPhoneOverride = false;
                }
            },
            copyPhoneToSwish() {
                // Only copy regular phone number if country code also is swedish
                const allowCopy =
                    this.fields.swishPhone === '' &&
                    this.allowSwishPhoneOverride;
                if (
                    allowCopy &&
                    this.fields.phoneCountry &&
                    this.fields.phoneCountry.iso2 === 'se'
                ) {
                    this.fields.swishPhone = this.fields.phone;
                }
            },
            onTreeCountUpdate(diff = 0) {
                const count = parseInt(this.fields.treeCount) + diff;
                if (count > 0) {
                    this.fields.treeCount =
                        parseInt(this.fields.treeCount) + diff;
                }
                this.fields.quantity = parseInt(this.fields.treeCount);
            },
            onClickChangeDonation(type, url) {
                // Set store for the type to navigate to
                this.setStore(type, true);
                window.location.href = url;
            },
            parseParamsToQuery(params) {
                const arr = Object.keys(params)
                    .filter(p => params[p] !== '')
                    .map(p => `${p}=${params[p]}`);

                if (arr.length <= 0) {
                    return '';
                }
                return '?' + arr.join('&');
            },
            parseQueryToParams(query) {
                let params = {};
                query
                    .replace('?', '')
                    .split('&')
                    .map(q => {
                        const p = q.split('=');
                        params[p[0]] = p[1] || '';
                    });
                return params;
            },
            findCertificateImage(imageId) {
                if (imageId === null || this.certificateImages === undefined) {
                    return {};
                }

                const id = parseInt(imageId);
                const image = this.certificateImages.find(x => {
                    return x.ID === id;
                });
                return image || {};
            },
            getCertificateImageUrl(imageId) {
                if (!certificateImagesAreSelectable) return {};

                const image = this.findCertificateImage(imageId);
                return image.src || {};
            },
            clickSubmit(val, currentDevice = false) {
                this.currentSubmit = val;
                this.currentDevice = currentDevice;
                this.showBankIDLoadingSpinner = true;

                if (this.type === 'checkout') {
                    trackCart('checkout', {step: 4}, cartInfo)
                }
            },
            onSubmit(e) {
                // Follow formactions on certain actions
                if(['certificate-pdf', 'update-cart'].indexOf(this.currentSubmit) !== -1) {
                    return;
                }

                e.preventDefault();

                if (this.$v.forms.section.$invalid) {
                    this.error = true;
                    return;
                }
                this.error = false;

                const data = this.prepareData();

                this.xhrStatusSubmit = XHR_STATUS_LOADING;

                V(this.$refs.loaderScrollTo, 'scroll', {
                    duration: 500,
                    offset: 0,
                    delay: 200
                });
                this.$refs.submitButton.blur();

                if (this.currentDevice){
                  this.openBankIDOnCurrentDevice = false;
                  this.billectaBankIdAutoStartToken = null;
                }

                this.maybeGetFingerprint(this.type, data, this.currentDevice)
                    .then(_response => this.submitDonation(data))
                    .then(response => {
                        window.location = response.redirect;
                        this.showBankIDLoadingSpinner = false;
                    })
                    .catch(e => {
                        logger.error('Donation.maybeGetFingerprint failed', e);
                        this.xhrStatusSubmit = XHR_STATUS_ERROR;
                        this.submitErrorMessage = e.message;
                        this.showBankIDLoadingSpinner = false;
                    });
            },
            submitDonation(data) {
                this.xhrStatusSubmit = XHR_STATUS_LOADING;

                if (this.digitalSignature) {
                    data.digitalSignature = this.digitalSignature;
                }

                return new Promise((resolve, reject) => {
                    const url = '/wp-json/donation/v1/donation/';
                    http.post(url, data)
                        .then(response => {
                            if(response.orderId) {
                                this.orderId = response.orderId;
                            }
                            this.setStore();

                            if(response.error || !response.redirect) {
                                reject(response);
                                this.xhrStatusSubmit = XHR_STATUS_ERROR;
                            } else {
                                resolve(response);
                            }
                        })
                        .catch(e => {
                            logger.error('Donation.submitDonation failed', e);
                            this.xhrStatusSubmit = XHR_STATUS_ERROR;
                            reject(e);
                        });
                });
            },

            maybeGetFingerprint(_data, data, currentDevice) {
                this.xhrStatusMessageType = this.type;

                return new Promise((resolve, reject) => {
                    if (isMonthlyOrder) {
                        billecta
                            .getSigningFingerprint(this.formatSSN(), currentDevice)
                            .then(response => {
                                this.billectaQRCode = null;
                                this.billectaIsPolling = false;
                                this.billectaBankIdAutoStartToken = null;
                                this.digitalSignature = JSON.stringify(
                                    response
                                );
                            })
                            .then(_response => {
                                resolve();
                            })
                            .catch(e => {
                                this.billectaQRCode = null;
                                this.billectaIsPolling = false;
                                this.billectaBankIdAutoStartToken = null;
                                reject(e);
                            });
                    } else {
                        resolve();
                    }
                });
            },
            updateTotalCost() {
                let total = this.fields.amount * this.fields.quantity;
                if (isCertificateOrder && this.isPrintCertificate) {
                    total += this.certificatePrintCost;
                }
                this.totalCost = total;
            },

            setStore(type = this.type, validateOnCreate = false) {
                const store = {
                    fields: Object.assign({}, this.fields),

                    useCustomAmount: this.useCustomAmount,
                    showAddressFields: this.showAddressFields,
                    showAccountFields: this.showAccountFields,
                    showObfuscatedAddress: this.showObfuscatedAddress,
                    obfuscatedAddress: this.obfuscatedAddress,
                    allowSwishPhoneOverride: this.allowSwishPhoneOverride,

                    billectaBankAccountPublicId: this.billectaBankAccountPublicId,

                    touchedGroups: this.touchedGroups,
                    activeSection: this.activeSection,

                    orderId: this.orderId,

                    expire: Date.now() + 3600000
                };

                // Reset some fields that should not be stored
                store.fields.bankAccountNumberSelected = '';
                store.fields.clearingNo = '';
                store.fields.accountNo = '';

                if (validateOnCreate) {
                    store.validateOnCreate = true;
                }

                // Save to local storage
                this.$store.set(type, JSON.stringify(store));
            },

            getBankAccountsThisDevice() {
              this.showBankIDLoadingSpinner = true;
              this.getBankAccounts(false)
            },
            getBankAccountsOtherDevice() {
              this.showBankIDLoadingSpinner = true;
              this.getBankAccounts(true)
            },
            getBankAccounts(otherDevice = false) {
                if (!this.fields.bank) {
                    logger.error('Bank is not selected');
                    return;
                }

                if (!this.fields.ssn.length || this.$v.fields.ssn.$invalid) {
                    logger.error(
                        'User tried to get their bank accounts without a SSN, should not be possible'
                    );
                    return;
                }

                this.showAccountFields = false;
                this.xhrStatusBankAccount = XHR_STATUS_LOADING;

                const ongoingRequestPublicId = this.$store.get('billectaBankAccountPublicId');

                const promise = ongoingRequestPublicId
                    ? billecta.resumeBankAccount(ongoingRequestPublicId)
                    : billecta.getBankAccounts(this.fields.bank, this.formatSSN(), otherDevice);

                promise.then(response => {
                        this.billectaQRCode = null;
                        this.billectaIsPolling = false;
                        this.billectaBankIdAutoStartToken = null;
                        this.$store.remove('billectaBankAccountPublicId');
                        if (response.length) {
                            this.xhrStatusBankAccount = XHR_STATUS_SUCCESS;
                            this.bankAccountNumbers = response;
                        } else {
                            this.xhrStatusBankAccount = XHR_STATUS_EMPTY;
                        }
                        this.showBankIDLoadingSpinner = false;
                    })
                    .catch(e => {
                        this.xhrStatusBankAccount = XHR_STATUS_ERROR;
                        this.billectaQRCode = null;
                        this.billectaIsPolling = null;
                        this.billectaBankIdAutoStartToken = null;
                        this.$store.remove('billectaBankAccountPublicId');
                        logger.error('billecta.getBankAccounts failed', e);
                        this.showBankIDLoadingSpinner = false;
                    });
            },

            getOrderStatus(orderId) {
                return new Promise((resolve, reject) => {
                    http.get('/wp-json/transaction/v1/status/' + orderId)
                        .then(x => {
                            if (!x.success) {
                                reject(x);
                            } else if (x.status) {
                                resolve(x.status);
                            } else {
                                reject(x);
                            }
                        })
                        .catch(x => {
                            reject(x);
                        });
                });
            },

            updateFields: function(data) {
                Object.keys(data).map(key => {
                    let value = data[key];
                    if (value === 'false') {
                        value = false;
                    } else if (value === 'true') {
                        value = true;
                    }
                    this.fields[key] = value;
                });
            },

            setData: function(data) {
                this.orderId = data.orderId;
                // this.isLoggedIn = data.isLoggedIn;

                this.fields = data.fields;
                this.useCustomAmount = data.useCustomAmount;

                this.isOtherCertificate = data.isOtherCertificate;
                this.isPrintCertificate = data.isPrintCertificate;

                this.showAddressFields = data.showAddressFields;
                this.showAccountFields = data.showAccountFields;
                this.showObfuscatedAddress = data.showObfuscatedAddress;
                this.obfuscatedAddress = data.obfuscatedAddress;
                this.allowSwishPhoneOverride = data.allowSwishPhoneOverride;

                this.touchedGroups = data.touchedGroups;

                if (!this.showAddressFields && !this.$v.fields.ssn.$invalid && !this.obfuscatedAddress) {
                    this.showMissingAddressError = true;
                }

                // Open the first section which is either active or invalid
                const group = sections.find(s => {
                    return (
                        this.$v.groups[s].$invalid || data.activeSection === s
                    );
                });
                if (group !== undefined) {
                    this.activeSection = group;
                }
            },


            prepareData() {
                const fields = this.fields;

                const data = {
                    orderId: this.orderId,
                    postId: this.postId,
                    customerId: customerId,
                    type: this.type,
                    campaignCode: this.campaignCode,
                    amount: this.useCustomAmount
                        ? parseInt(fields.customAmount)
                        : fields.amount,
                    email: fields.email,
                    phone: fields.phone,
                    phoneCountry: fields.phoneCountry,
                    phoneSanitized: fields.phoneSanitized,
                    quantity: fields.quantity,
                    leadGuid,
                };

                // Force total cost to make sure it is same as displayed on frontend
                if (isClimateCompensation) {
                    data.totalCost = Math.round(this.totalCost);
                }

                if (this.showAddressFields || isCompanyOrder) {
                    data.firstName = fields.firstName;
                    data.lastName = fields.lastName;
                    data.address = fields.address;
                    data.address2 = fields.address2;
                    data.postalCode = fields.postalCode;
                    data.city = fields.city;
                } else if (!this.showAddressFields && !isCompanyOrder) {
                    data.sparId = fields.sparId;
                }

                if (this.fields.showShippingFields) {
                    data.shippingFirstName = fields.shippingFirstName;
                    data.shippingLastName = fields.shippingLastName;
                    data.shippingAddress = fields.shippingAddress;
                    data.shippingAddress2 = fields.shippingAddress2;
                    data.shippingPostalCode = fields.shippingPostalCode;
                    data.shippingCity = fields.shippingCity;
                }

                if (isMonthlyOrder) {
                    data.payment = 'automatic_payment_gateway';
                    data.bank = fields.bank;
                    data.legalAgreement = legalAgreement;

                    if (this.showAccountFields) {
                        data.clearingNo = fields.clearingNo;
                        data.accountNo = fields.accountNo;
                    } else {
                        const account = this.bankAccountNumbers[
                            fields.bankAccountNumberSelected
                        ];
                        data.clearingNo = account.clearingNo;
                        data.accountNo = account.accountNo;
                    }
                } else {
                    data.payment = fields.payment;
                }

                if (isCompanyOrder) {
                    data.companyName = fields.companyName;
                    data.companyRegistrationNumber =
                        fields.companyRegistrationNumber;
                } else {
                    data.ssn = fields.ssn;
                }

                if (isCertificateOrder) {
                    if (this.isPrintCertificate) {
                        data.certificate = 'print';
                    } else {
                        data.certificate = 'digital';
                    }

                    data.certificateMessage = fields.certificateMessage;
                    data.certificateName = fields.certificateName;
                    data.certificateFromName = fields.certificateFromName;
                    data.certificateImageId = fields.certificateImageId;

                    if (this.isOtherCertificate) {
                        data.certificateOther = 'digital';
                        data.recipientEmail = fields.recipientEmail;
                    }
                    if (this.isPrintCertificate) {
                        data.recipientSelect = fields.recipientSelect;
                        data.recipientDate = fields.recipientDate;
                        data.recipientSelectedDate = fields.recipientSelectedDate;
                        data.recipientName = fields.recipientName;
                        data.recipientAddress = fields.recipientAddress;
                        data.recipientAddress2 = fields.recipientAddress2;
                        data.recipientPostalCode = fields.recipientPostalCode;
                        data.recipientCity = fields.recipientCity;
                    }
                }

                if (isTreeOrder) {
                    data.treeCount = fields.treeCount;
                }

                if (fields.payment === 'swish') {
                    data.swishPhone = fields.swishPhone;
                    data.swishPhoneCountry = fields.swishPhoneCountry;
                    data.swishPhoneSanitized = fields.swishPhoneSanitized;
                }

                return data;
            },

            trackOnClickNextSection(group) {
                let category = GIFT_TYPE_TRACKING_NAMES[this.type];
                if(this.trackingName !== '' && this.trackingName !== null) {
                    category = trackingName;
                }
                const action = SECTION_TRACKING_NAMES[group];

                let label = null;
                if (action === 'belopp') {
                    label = this.fields.amount * this.fields.quantity;
                } else if (action === 'betalsatt') {
                    label = this.fields.payment;
                } else if (action === 'gavobevis') {
                    // Change
                    if (this.isPrintCertificate) {
                        label = 'tryckt';
                    } else {
                        label = 'digitalt';
                    }
                } else if (
                    action === 'personuppgifter' ||
                    action === 'foretagsuppgifter' ||
                    action === 'autogiroregistrering'
                ) {
                    label = '';
                }

                if (category !== 'checkout') {
                    trackEvent('checkoutSteps', category, action, label);
                } else {
                    let step = 0;
                    switch (action) {
                        case 'varukorg':
                            step = 1;
                            break;
                        case 'personuppgifter':
                        case 'foretagsuppgifter':
                            step = 2;
                            break;
                        case 'betalsatt':
                            step = 3;
                            break;
                    }

                    trackCart('checkout', {step: step}, cartInfo)
                }
            },

            getMemorialOrderData: function(name, fields) {
                fetch('/wp-json/memorial/v1/name/'+name)
                    .then(response => response.json())
                    .then(data => {
                        fields.memorialOrderData = data;
                    });
            },

            memorialOrderDateSelected: function(fields) {
              $.each(fields.memorialOrderData, function(_key, value) {
                  if(fields.recipientSelectedDate === value.orderId) {
                      fields.recipientDate = value.recipientDate;
                      fields.recipientName = value.recipientName;
                      fields.recipientAddress = value.recipientAddress1;
                      fields.recipientAddress2 = value.recipientAddress2;
                      fields.recipientPostalCode = value.recipientPostalCode;
                      fields.recipientCity = value.recipientCity;
                  }
              })

          },
        }
    });

    // This will enable the button in the UI when AutoStartToken is received OR after few seconds.
    // it is implemented like this because we do not know if the bank supports AutoStartToken or not
    let _pollingTimeout = null;
    billectaEventListener.onReceivedSigningFingerprintStatus = () => {
        if (!app.billectaIsPolling && _pollingTimeout === null) {
            setTimeout(() => {
                app.billectaIsPolling = true;
                _pollingTimeout = null;
            }, 9500)
        }
    }

    billectaEventListener.onReceivedBankAccountStatus = () => {
        if (!app.billectaIsPolling && _pollingTimeout === null) {
            // Some banks are rather slow with generating the autostarttoken, 9500 should give the app
            // 3 polls to before lighting the button. Hopefully we have the token by then.
            setTimeout(() => {
                app.billectaIsPolling = true;
                _pollingTimeout = null;
            }, 9500);
        }
    }

    billectaEventListener.onReceivedBankAccountInititate = (publicId) => {
        app.$store.set('billectaBankAccountPublicId', publicId);
    }

    billectaEventListener.onReceivedCurrentDevice = (currentDevice) => {
        app.billectaCurrentDevice = currentDevice;
    }

    billectaEventListener.onReceivedQR = (qr) => {
        app.billectaQRCode = qr
        app.billectaIsPolling = true
    }

    billectaEventListener.onReceivedBankIdAutoStartToken = (token) => {
        app.billectaBankIdAutoStartToken = token
        app.billectaIsPolling = true
    }
};

export default Donation;
