import http from './http'
import {awaitPollStatus} from "./utils";

class EventListener{
    onReceivedQR = (qr) => {console.log("Received unhandeled event `onReceivedQR` with data: " + qr)}
    onReceivedBankIdAutoStartToken = (token) => {console.log("Received unhandeled event `onReceivedBankIdAutoStartToken` with data: " + token)}
    onReceivedBankAccountStatus = () => {console.log("Received bank account status")}
    onReceivedSigningFingerprintStatus = () => {console.log("Received signing fingerprint status")}
    onReceivedCurrentDevice = (currentDevice) => {console.log("Received if BankId is on current device")}
}

export const eventListener = new EventListener()

function getBankAccounts(bank, ssn, otherDevice) {
    const url = "/wp-json/billecta/v1/bank/accounts/initiate/" + bank + '/' + ssn + '?otherDevice=' + otherDevice

    const currentDevice = otherDevice === true ? false : true;
    eventListener.onReceivedCurrentDevice(currentDevice);

    return new Promise((resolve, reject) => {
        http.post(url).then(x => {
            const publicId = x['PublicId'];

            eventListener.onReceivedBankAccountInititate(publicId);

            awaitPollStatus(getBankAccountStatus, publicId).then(response => {
                resolve(parseBankAccountStatusResponse(response))
            }).catch(x => reject(x))

        }).catch(x => reject(x))
    })
}

function parseBankAccountStatusResponse(response) {
    return response['AccountNumbers'].filter(x => (
        x['AutogiroCompatible'] === 'Approved'
    )).map(x => ({
        clearingNo: x['ClearingNo'],
        accountNo: x['AccountNo'],
        name: x['Name'],
    }))
}

function resumeBankAccount(publicId) {
    return new Promise((resolve, reject) => {
        awaitPollStatus(getBankAccountStatus, publicId).then(response => {
            resolve(parseBankAccountStatusResponse(response))
        }).catch(x => reject(x))
    });
}

const getAccountStatusUrl = (publicId) =>
    "/wp-json/billecta/v1/bank/accounts/" + publicId

function getBankAccountStatus(publicId) {
    const url = getAccountStatusUrl(publicId)

    return new Promise((resolve, reject) => {
        http.get(url).then(x => {
            eventListener.onReceivedBankAccountStatus();

            if (x['QR']) {
                eventListener.onReceivedQR(x['QR'])
            }

            if (x['BankIdAutostartToken']) {
                eventListener.onReceivedBankIdAutoStartToken(x['BankIdAutostartToken'])
            }

            if (x && x['Status'] === 'Waiting') {
                resolve('repeat')
            } else if (x && x['Status'] === 'Success') {
                resolve(x)
            } else {
                reject(x)
            }
        }).catch(x => reject(x))
    })
}

function getSigningFingerprint(ssn, currentDevice) {
    const url = "/wp-json/billecta/v1/bankid/sign/initiate/" + ssn + '?currentDevice=' + currentDevice

    eventListener.onReceivedCurrentDevice(currentDevice);

    return new Promise((resolve, reject) => {
        http.post(url).then(x => {
            awaitPollStatus(getSigningFingerprintStatus, x['ReferenceToken']).then(response => {
                resolve(response)
            }).catch(x => reject(x))
        }).catch(x => reject(x))
    })
}

function getSigningFingerprintStatus(publicId) {
    const url = "/wp-json/billecta/v1/bankid/sign/" + publicId

    return new Promise((resolve, reject) => {
        http.get(url).then(x => {
            eventListener.onReceivedSigningFingerprintStatus();

            if (x['QR']) {
                eventListener.onReceivedQR(x['QR'])
            }

            if (x['AutoStartToken']) {
                eventListener.onReceivedBankIdAutoStartToken(x['AutoStartToken'])
            }

            if (x && ['Started', 'Waiting', 'UserSign', 'OutstandingTransaction'].indexOf(x['Status']) !== -1) {
                resolve('repeat')
            } else if (x && x['Status'] === 'Complete') {
                resolve(x)
            } else {
                reject(x)
            }
        }).catch(x => reject(x))
    })
}

export default {
    getBankAccounts,
    resumeBankAccount,
    getSigningFingerprint
}
