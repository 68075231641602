/**
 * Repeats a function that return a promise until it
 * resolve something else than "repeat" or is
 * rejected with reject("reject")
 *
 * @param func - a function that resolve a promise
 * @param param - param to pass to the passed func
 * @param timeout - Timeout in microseconds (when to stop polling)
 * @param interval - How often the poll should run, in microseconds
 *
 * @returns {Promise<unknown>}
 */
export function awaitPollStatus(func, param, timeout = null, interval = null) {
  let endTime = Number(new Date()) + (timeout || 2 * 60 * 1000);
  interval = interval || 3000;

  function _poll(resolve, reject) {
    if (Number(new Date()) > endTime) {
      reject('Timeout');
      return;
    }

    func(param).then(x => {
      if (x === 'repeat') {
        setTimeout(_poll, interval, resolve, reject)
      } else {
        resolve(x);
      }
    }).catch((e) => {
      if (e === 'reject') {
        reject()
      } else {
        setTimeout(_poll, interval, resolve, reject)
      }
    })
  }

  return new Promise(_poll);
}
