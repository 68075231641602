import http from './http'

function getSparPerson(ssn) {
    const url = '/wp-json/spar/v1/obfuscate/ssn/' + ssn
    return new Promise((resolve, reject) => {
        http.post(url).then(x => {
            if(!x.success) {
                reject(x);
            } else {
                let address = x.obfuscate.currentAddress.Utdelningsadress2;
                let address2 = '';
                if(x.obfuscate.currentAddress.Utdelningsadress1) {
                    address2 = address;
                    address = x.obfuscate.currentAddress.Utdelningsadress1;
                }

                resolve({
                    sparId: x.spar_id,
                    obfuscate: {
                        firstName: x.obfuscate.personDetails.Fornamn,
                        lastName: x.obfuscate.personDetails.Efternamn,
                        address: address,
                        address2: address2,
                        postalCode: x.obfuscate.currentAddress.PostNr,
                        city: x.obfuscate.currentAddress.Postort,
                    }
                });
            }
        }).catch(x => reject(x))
    })
}

export default getSparPerson
