/* global $ */

const Agroforestry = (el) => {
    const $el = $(el);

    let $open = null;

    $el.find('.js-agroforestry-click').click((e) => {
        e.preventDefault();
        const $toggle = $($(e.target).data('toggle'));
        if($toggle.length) {
            $toggle.fadeIn(() => {
                $open = $toggle;
                $toggle.find('.js-agroforestry-close').click(() => {
                    $toggle.fadeOut(() => {
                        $toggle.find('.js-agroforestry-close').unbind('click');
                        $open = null;
                    });
                });
            });
        }
    });

    $(document).click((ev) => {
        if($open !== null) {
            const $parent = $open.children().first();
            if($parent.has(ev.target).length === 0 && !$parent.is(ev.target)) {
                $open.fadeOut(() => {
                    $open.find('.js-agroforestry-close').unbind('click');
                    $open = null;
                });
            }
        }
    });
};

export default Agroforestry;
