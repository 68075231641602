/* global $ */

const Minicart = (el) => {
    let $el = $(el);
    $el.click(function (e) {
        e.preventDefault();

        $('body').toggleClass('js-state-open-minicart');
    });
};

export default Minicart;
