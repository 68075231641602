import Vue from 'vue';
import Vuelidate from 'vuelidate';
import {
    decimal,
    numeric,
    required,
    requiredIf,
} from 'vuelidate/lib/validators';
import Iti from 'intl-tel-input';
import Velocity from 'velocity-animate';
import http from './http';
import {
    validateIf,
} from './donation/validators';
import {
    XHR_STATUS_IDLE,
    XHR_STATUS_LOADING,
    XHR_STATUS_ERROR,
} from './donation/constants';
import Logger from './error-handler';

let V = Velocity;

Vue.use(Vuelidate);

const TMRegisterLead = _el => {
    // Settings from wp
    const {
        amounts,
        sectionsData,
        currency,
        locale,
        allowDecimalAmounts,
        pageUrl,
        pageId,
        minAmount,
    } = settings;

    const type = "";

    const defaultAmounts = [...amounts];
    const defaultAmount = defaultAmounts[1];

    const sections = Object.keys(sectionsData);
    const sectionValidate = sections
        .map(s => `groups.${s}`)
    //.concat('groups.gdpr');

    const forms = {
        section: sectionValidate
    };

    const defaultGroups = Object.assign(
        {},
        ...sections.map(i => ({ [i]: false }))
    );

    const intlTelUtils =
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js';
    const intlTelLoaded = window.intlTelInputGlobals.loadUtils(intlTelUtils);

    var _app = new Vue({
        el: '#tm-register-lead',
        directives: {
            intltelinput: {
                inserted: (el, _binding, vnode) => {
                    const vueApp = vnode.context;
                    const input = el.querySelector('input[type="tel"]');
                    const countryKey = `${input.name}Country`;
                    const sanitizedKey = `${input.name}Sanitized`;

                    let defaultCountry = 'SE';
                    if (
                        vueApp.fields &&
                        vueApp.fields[countryKey] &&
                        vueApp.fields[countryKey].iso2
                    ) {
                        defaultCountry = vueApp.fields[countryKey].iso2;
                    }

                    const options = {
                        separateDialCode: true,
                        initialCountry: defaultCountry
                    };
                    if (input.name === 'swishPhone') {
                        options.onlyCountries = ['SE'];
                        options.allowDropdown = false;
                    } else {
                        options.preferredCountries = ['SE'];
                    }

                    const iti = new Iti(input, options);

                    intlTelLoaded.then(() => {
                        vueApp.fields[sanitizedKey] = iti.getNumber();
                        vueApp.fields[
                            countryKey
                        ] = iti.getSelectedCountryData();

                        input.addEventListener('countrychange', () => {
                            vueApp.fields[
                                countryKey
                            ] = iti.getSelectedCountryData();
                        });
                    });
                },
                update: (el, _binding, vnode) => {
                    const input = el.querySelector('input[type="tel"]');
                    const countryKey = `${input.name}Country`;
                    const sanitizedKey = `${input.name}Sanitized`;
                    const isValidKey = `${input.name}IsValid`;

                    const iti = window.intlTelInputGlobals.getInstance(input);

                    vnode.context.fields[sanitizedKey] = iti.getNumber();
                    vnode.context.fields[isValidKey] = window.intlTelInputUtils.isValidNumber(iti.getNumber());
                    vnode.context.fields[
                        countryKey
                    ] = iti.getSelectedCountryData();
                }
            }
        },
        data: {
            type,
            amounts: defaultAmounts,
            minAmount,
            useCustomAmount: false,
            hideFieldError: '',
            activeSection: sections[0],
            sections: sections,
            fields: {
                amount: defaultAmount,
                phone: '',
                phoneSanitized: false,
                phoneIsValid: false,
                customAmount: '',
            },
            groups: {
                amount: ['fields.amount'],
                phone: ['fields.phone'],
            },
            touchedGroups: {...defaultGroups},
            groupError: {...defaultGroups},
            error: false,

            // XHR
            xhrStatusOrderStatus: XHR_STATUS_IDLE,
            xhrStatusBankAccount: XHR_STATUS_IDLE,
            xhrStatusSubmit: XHR_STATUS_IDLE,
            xhrStatusMessageType: type,
            submitErrorMessage: '',
        },
        validations() {
            return {
                fields: {
                    amount: {
                        decimal,
                        minValue: v => v >= this.minAmount,
                        required,
                    },
                    customAmount: {
                        requiredIf: requiredIf(() => this.useCustomAmount),
                        validateCustomAmount: validateIf(
                            numeric,
                            this.useCustomAmount
                        )
                    },
                    phone: {
                        validatePhone: () =>this.fields.phoneIsValid,
                        required,
                    }
                },
                groups: this.groups,
                forms: forms,
            }
        },
        watch: {
        },
        filters: {
            formatAmount: val => {
                let amount = parseFloat(val);
                if (isNaN(amount)) {
                    amount = 0;
                }

                const options = {
                    currency: currency,
                    style: 'currency'
                };

                if (!allowDecimalAmounts) {
                    options.minimumFractionDigits = 0;
                    options.maximumFractionDigits = 0;
                }
                return amount.toLocaleString(locale, options);
            }
        },
        methods: {
            setData: function(data) {
                this.fields = data.fields;
            },
            onClickCustomAmount: function() {
                if (!this.useCustomAmount) {
                    this.$refs.inputCustomAmount.focus();
                    this.fields.amount = this.fields.customAmount;
                    this.useCustomAmount = true;
                    this.hideFieldError = '';
                }
            },
            onChangeAmount: function(_e) {
                this.useCustomAmount = false;
                this.hideFieldError = '';
            },
            onInputCustomAmount: function() {
                this.fields.amount = this.fields.customAmount;
                this.hideFieldError = 'customAmount';
            },
            onChangeCustomAmount: function() {
                this.fields.amount = this.fields.customAmount;
                this.hideFieldError = '';
            },
            onEnterSection: function(el, done) {
                V(el.parentElement, 'scroll', {
                    duration: 500,
                    offset: -100,
                    delay: 200
                });
                V(el, 'slideDown', { duration: 500, complete: done });
            },
            onInputField: function(e) {
                if (this.$v.fields[e.target.name].$error) {
                    this.hideFieldError = e.target.name;
                }
            },
            onChangeField: function(e) {
                this.hideFieldError = '';
                this.$v.fields[e.target.name].$touch();
            },
            onClickChangeSection: function(section) {
                this.activeSection = section;
            },
            onClickNextSection: function(section = false) {
                this.$v.groups[section].$touch();

                if (!section) {
                    this.activeSection = '';
                    return;
                }

                this.touchedGroups[section] = true;

                if (this.$v.groups[section].$invalid) {
                    this.groupError[section] = true;

                    if (
                        section === 'personal' &&
                        !this.showAddressFields &&
                        this.$v.fields.sparId.$invalid
                    ) {
                        this.showMissingAddressError = true;
                    }
                    return;
                }

                this.groupError[section] = false;

                // this.trackOnClickNextSection(section);

                const currentIndex = this.sections.indexOf(this.activeSection);
                const nextItems = this.sections.slice(currentIndex + 1);
                const nextUntouchedItem = nextItems.find(x => {
                    return !this.touchedGroups[x] || this.$v.groups[x].$invalid;
                });
                this.activeSection = nextUntouchedItem || 'gdpr';
                // this.setStore();
            },
            onAfterEnterSection: function(el) {
                V(el.parentElement, 'scroll', { duration: 500, offset: -100 });
            },
            onLeaveSection: function(el, done) {
                V(el, 'slideUp', { duration: 200, complete: done });
            },
            onEnterExpandable: function(el, done) {
                V(el, 'slideDown', {
                    duration: 300,
                    easing: 'easeOutQuart',
                    complete: done
                });
            },
            onLeaveExpandable: function(el, done) {
                V(el, 'slideUp', {
                    duration: 200,
                    easing: 'easeOutQuart',
                    complete: done
                });
            },
            onSubmit(e) {
                e.preventDefault();

                const fields = this.fields;
                const data = {
                    amount: (
                        this.useCustomAmount
                        ? parseInt(fields.customAmount)
                        : fields.amount
                    ),
                    phone: fields.phoneSanitized,
                    submittedFrom: pageId,
                }

                this.xhrStatusSubmit = XHR_STATUS_LOADING;

                this.$refs.submitButton.blur();

                const url = '/wp-json/tm-lead/v1/lead/';
                http.post(url, data)
                    .then(response => {
                        if (response.error) {
                            this.submitErrorMessage = response.message;
                            this.xhrStatusSubmit = XHR_STATUS_ERROR;
                            console.log('exti');
                            return;
                        }

                        window.location = pageUrl+"?success=1#tm-register-lead";
                        return response;
                    })
                    .catch(e => {
                        this.submitErrorMessage = e.message;
                        this.xhrStatusSubmit = XHR_STATUS_ERROR;
                    });
            }
        }
    });
}

export default TMRegisterLead;
