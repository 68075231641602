/* global $ */

import Cookies from 'js-cookie';

const Cookie = (el) => {
    const $el = $(el);

    $el.removeClass('js-state-cookie-initial').hide();

    const isClosed = Cookies.get('cookie-popup-closed_1');
    if (isClosed === undefined) {
        $el.slideDown();
    }

    $el.find('.js-cookie-close').click(function (e) {
        e.preventDefault();
        $(el).slideUp('fast');
        Cookies.set('cookie-popup-closed_1', 'true', {expires: 365});
    });
};


export default Cookie;
