/* global $ */

const Video = (el) => {
    const $el = $(el);

    $el.click((e) => {
        const iframe = $el.find('iframe')[0];
        if (!iframe) {
            return;
        }

        e.preventDefault();
        $el.addClass('js-state-video-active');
        iframe.src += '&autoplay=1';

    });
};

export default Video;
