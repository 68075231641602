export const GIFT_TYPE_STANDARD = 'standard';
export const GIFT_TYPE_MONTHLY = 'monthly';
export const GIFT_TYPE_MEMORIAL = 'memorial';
export const GIFT_TYPE_SHOP = 'checkout';
export const GIFT_TYPE_MEMORIAL_BUSINESS = 'memorial_company';
export const GIFT_TYPE_TREE = 'tree';
export const GIFT_TYPE_TREE_BUSINESS = 'tree_company';
export const GIFT_TYPE_BUSINESS = 'business';
export const GIFT_TYPE_CLIMATE_COMPENSATION = 'climate_compensation';
export const GIFT_TYPE_CLIMATE_COMPENSATION_PERSONAL =
  'climate_compensation_personal';

let _GIFT_TYPE_TRACKING_NAMES = {};
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_STANDARD] = 'engangsgava';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_MONTHLY] = 'manadsgivare';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_BUSINESS] = 'foretagsgava';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_MEMORIAL] = 'minnesgava';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_SHOP] = 'checkout';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_MEMORIAL_BUSINESS] = 'minnesgava foretag';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_TREE] = 'gebortettträd';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_TREE_BUSINESS] = 'gebortettträd foretag';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_CLIMATE_COMPENSATION] =
  'klimatkompensation foretag';
_GIFT_TYPE_TRACKING_NAMES[GIFT_TYPE_CLIMATE_COMPENSATION_PERSONAL] =
  'klimatkompensation privat';

export const GIFT_TYPE_TRACKING_NAMES = _GIFT_TYPE_TRACKING_NAMES;

export const SECTION_TRACKING_NAMES = {
  amount: 'belopp',
  treeCount: 'belopp',
  cart: 'varukorg',
  payment: 'betalsatt',
  certificate: 'gavobevis',
  personal: 'personuppgifter',
  company: 'foretagsuppgifter',
  automaticPayment: 'autogiroregistrering'
};

export const XHR_STATUS_IDLE = 'idle';
export const XHR_STATUS_LOADING = 'loading';
export const XHR_STATUS_SUCCESS = 'success';
export const XHR_STATUS_ERROR = 'error';
export const XHR_STATUS_EMPTY = 'empty';
