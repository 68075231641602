import $ from 'jquery';

const EmbedMessage = (el) => {
    const $el = $(el);
    const $button = $el.find('.js-embed-button');

    $button.click((e) => {
        const content = $el.data('content');
        $el.html(content);
        $el.addClass('js-state-video-active');
    });
}

export default EmbedMessage;
