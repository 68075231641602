/* global $ */

const CertificateForm = (el) => {
    const self = this;

    const $el = $(el);
    const $form = $el.closest('form');
    $el.find('#certificateOther').change(function(e) {
        const show = $(this).is(':checked');
        $el.find($(this).data('toggle')).slideToggle(show);
    });

    const $msgField = $el.find('[name=certificateMessage]');
    const $fromField = $el.find('[name=certificateFromName]');
    const $quantityField = $form.find('[name=quantity]');

    const $modal = $el.find('.js-modal');
    $el.find('.js-modal-open').click((e) => {
        e.preventDefault();
        $modal.fadeIn(() => {
            $modal.addClass('js-state-open');
            const message = $msgField.val().replace(/[\r\n]/g, '<br>');
            $el.find('.js-modal-message').html(message);

            const quantity = $quantityField.val();
            if(quantity > 1) {
                $el.find('.js-modal-quantity').show().find('span').text(quantity);
            } else {
                $el.find('.js-modal-quantity').hide().find('span').text('');
            }

            if($fromField.length) {
                const fromName = $fromField.val();
                const fromText = $fromField.data('text').replace('%s', fromName);
                $el.find('.js-modal-from-name').text(fromText);
            }
        });
    });

    $el.find('.js-modal-close').click(() => {
        $modal.removeClass('js-state-open');
        $modal.fadeOut('slow');
    });

    const $previewToggle = $el.find('.js-preview');
    $previewToggle.find('button').click(function(e) {
        e.preventDefault();
        const $btn = $(this);
        if($btn.hasClass('js-state-active')) {
            return;
        }

        const val = $btn.data('type');
        $previewToggle.find('button').removeClass('js-state-active');
        $btn.addClass('js-state-active');
        $previewToggle.find('[data-preview]').hide();
        $previewToggle.find(`[data-preview="${val}"]`).show();
    });

    $msgField.change(() => {
        const value = $msgField.val();
        const maxRows = $msgField.data('maxrows') || 7;
        let error = false;
        if(value.split('\n').length > maxRows) {
            error = true;
        }
        if(value === '' || value.length > $msgField.attr('maxlength')) {
            error = true;
        }

        showFieldError($msgField, error);
    });

    $fromField.change(() => {
        const value = $fromField.val();
        let error = false;
        if(value === '' || value.length > $fromField.attr('maxlength')) {
            error = true;
        }

        showFieldError($fromField, error);
    });
};

const showFieldError = ($el, error) => {
    const $errorMsg = $el.next('.js-error-msg');
    if(error) {
        $errorMsg.slideDown();
        $el.addClass('js-state-error');
    } else {
        $el.removeClass('js-state-error');
        if($errorMsg.is(':visible')) {
            $errorMsg.slideUp();
        }
    }
};

const DigitalCertificate = (el) => {
    const $el = $(el);
    const $more = $el.find('.js-certificate-more');
    $more.click(() => {
        $('html, body').animate({
            scrollTop: 80
        });
    });

    $(window).scroll(() => {
        const top = $(document).scrollTop();
        if(top >= 50) {
            $el.addClass('js-state-more-active');
        } else {
            $el.removeClass('js-state-more-active');
        }
    });
};

const UnloadDigitalCertificate = (el) => {
    const $el = $(el);
    const $more = $el.find('.js-certificate-more');
    if($more.length) {
        $(window).scrollTop(0);
    }
};

export { CertificateForm, DigitalCertificate, UnloadDigitalCertificate };
