/* global $, server_resp */

import http from './http'
import { awaitPollStatus } from "./utils";

const Checkout = () => {
    const {
        type,
        paymentMethod,
        orderId,
        completedUrl,
        paymentUrl,
    } = checkoutLoader;
    if(type !== 'swish' && type !== 'payex') {
        return;
    }

    const func = type === 'swish' ? pollSwish : pollPayex;

    awaitPollStatus(func, orderId, 3 * 60 * 1000, 3000).then(x => {
      window.location = completedUrl;
    }).catch(x => {
      window.location = paymentUrl;
    });
};

const pollPayex = (orderId) => {
    return new Promise((resolve, reject) => {
        http.get('/wp-json/transaction/v1/status/' + orderId).then(x => {
            if(!x.success) {
                reject('reject');
            } else if(x.status === 'completed') {
                resolve(x);
            } else if(x.status === 'pending') {
                resolve('repeat');
            } else {
                reject('reject');
            }
        }).catch(x => {
            reject(x);
        });
    });
};

const pollSwish = (orderId) => {
	return new Promise((resolve, reject) => {
		http.get('/wp-json/transaction/v1/status/' + orderId).then(x => {
			if(!x.success) {
				reject('reject');
			} else if(x.status === 'completed') {
				resolve(x);
			} else if(x.status === 'pending') {
				resolve('repeat');
			} else {
				reject('reject');
			}
		}).catch(x => {
			reject(x);
		});
	});
};

export default Checkout;
