import {GIFT_TYPE_TRACKING_NAMES} from './donation/constants'

export function trackEvent(event, category = null, action = null, label = null, extra = {}) {
    let data = {event, ...extra};

    if (category) {
        data['eventCategory'] = category;
    }
    if (action) {
        data['eventAction'] = action;
    }
    if (label !== null) { // Allow empty strings since it might be on purpose
        data['eventLabel'] = label;
    }
    data = {...data, ...extra}

    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
}

export function trackCart(event, actionField = null, products = null, extra = {}) {
    let data = {event, ...extra};
    let ecommerce = {
        [event]: {}
    }

    if (actionField) {
        ecommerce[event]['actionField'] = actionField;
    }
    if (products) {
        ecommerce[event]['products'] = products;
    }
    data = {...data, ecommerce}

    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
}

export default {
    trackEvent,
    trackCart,
    GIFT_TYPE_TRACKING_NAMES,
}